<template>
    <v-container fluid>
      <v-card>
        <v-toolbar color="secondary" flat>
          <v-toolbar-title>New House Bank Account</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="primary" small text to="/banking/house-bank-accounts">
            <v-icon left dark>mdi-keyboard-backspace</v-icon>Back
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <!-- editor -->
            <editor :initial="dataItem" @data="save"></editor>
          <!-- end -->
        </v-card-text>
      </v-card>
    </v-container>
</template>

<script>
import Editor from '../_components/editor.vue';
import { mapGetters } from 'vuex';
export default {
  components: {
    Editor
  },
  computed: {
    ...mapGetters(['user'])
  },
  data: () => ({
    dataItem: {}
  }),
  methods: {
    save(data) {
      data.subscriber = this.user._id;
      const url = "/customer";
      const self = this;
      this.$store
        .dispatch("post", { url, data })
        .then(res => {
          if (res.state) {
            self.$router.push('/customers');
          } else {
            this.$refs.snackbar.show(res.msg, "red");
          }
        })
        .catch(err => {
          this.$refs.snackbar.show(err, "red");
        });
    }
  }
};
</script>